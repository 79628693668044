<template>
    <div class="banner about__banner fix-top">
        <div class="container">
            <div class="wrapper">
                <h2>Term & Condition</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt at provident iusto eligendi omnis perferendis iste suscipit possimus! Fugiat minima quod quidem ab aliquam assumenda aspernatur consectetur, dolorum quisquam totam exercitationem, sed dolores, hic voluptas quaerat pariatur eos voluptates. Maiores exercitationem officiis quisquam odio hic obcaecati tempore consequuntur earum eveniet.</p>
                <br>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt at provident iusto eligendi omnis perferendis iste suscipit possimus! Fugiat minima quod quidem ab aliquam assumenda aspernatur consectetur, dolorum quisquam totam exercitationem, sed dolores, hic voluptas quaerat pariatur eos voluptates. Maiores exercitationem officiis quisquam odio hic obcaecati tempore consequuntur earum eveniet.</p>
            </div>
        </div>
    </div>
</template>
<script>
import { useMeta } from 'vue-meta'
// import { onMounted } from 'vue';
export default ({
    name: 'TermConditions',
    data() {
        return {
            tnc:'',
        }
    },
    mounted() {
        console.log('Term & Conditions Component')
    },
    setup() {
        const { meta } = useMeta({});
        meta.title = 'Terms & Condition'
        meta.description = 'Welcome to Terms & Condition page.'
        // onMounted(async () => {
        //     let response = await fetch('https://ricoman.bigwavedevelopment.com/back-end/wp-json/wp/v2/pages?slug=home-page');
        //     let data = await response.json();
        //     data[0].yoast_head_json.schema = {};
        //     let dataset = data[0].yoast_head_json;
        //     Object.entries(dataset).forEach(([key, value]) => {
        //         if (value != '')
        //             meta[key] = value;
        //     });
        //     console.log(dataset)
        //     console.log(meta)
        // });
        return { meta };
    },
})
</script>