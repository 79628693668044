<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Autoplay,  Navigation } from 'swiper/modules';

  // Import Swiper styles
  import 'swiper/css';
  // import 'swiper/css/pagination';
  import 'swiper/css/navigation';

  export default {
    props: {
      blogs: {
        type: Array,
        required: true
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Autoplay,  Navigation],
      };
    },
  };
</script>

<template>
  <div class="products_worked">
    <div class="container">
      

      <swiper 
        v-if="blogs.length > 1"
        :slides-per-view="3"
        :space-between="30"
        :autoplay="{ delay: 200500, disableOnInteraction: false }"
        :pagination="{ clickable: true }"
        :navigation="true"
        :modules="modules"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :breakpoints="{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          }
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(row , index) in blogs" :key="index">
          <div class="blog-sec-box">
            <a :href="row.blog_url" target="_blank">
              <div class="blog-img">
                <img :src="row.image" alt="Blog_img"/>
              </div>
              <div class="blog-text">
                <h3>{{row.title}}</h3>
                <p>{{row.content}} </p>
              </div>
            </a>
          </div>
        </swiper-slide>
       
      </swiper>
       <template v-else>
        <div class="static-blog" v-for="(row , index) in blogs" :key="index">
           <div class="blog-sec-box">
            <a :href="row.blog_url" target="_blank">
              <div class="blog-img">
                <img :src="row.image" alt="Blog_img"/>
              </div>
              <div class="blog-text">
                <h3>{{row.title}}</h3>
                <p>{{row.content}} </p>
              </div>
            </a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
