<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Autoplay,  Navigation } from 'swiper/modules';

  // Import Swiper styles
  import 'swiper/css';
  // import 'swiper/css/pagination';
  import 'swiper/css/navigation';

  export default {
    props: {
      testimonialsdata: {
        type: Array,
        required: true
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Autoplay,  Navigation],
      };
    },
  };
</script>

<template>
  <div class="products_worked">
    <div class="container">
      

      <swiper
        :slides-per-view="2"
        :space-between="10"
        :autoplay="{ delay: 150000, disableOnInteraction: false }"
        :pagination="{ clickable: true }"
        :navigation="true"
        :modules="modules"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        :breakpoints="{
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 20,
          }
        }"
        class="mySwiper"
      >
        <swiper-slide v-for="(row , index) in testimonialsdata" :key="index">
          <div class="textimonial-box" >
            <p>
                {{row.testimonials_comments}}
            </p>
            <div class="testimonial-btm">
                <h5>{{row.testimonials_designation}}</h5>
                <h6>{{row.testimonials_partner}}</h6>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      
    </div>
  </div>
</template>
