<template>
    <div class="mnht">
        <div v-if="isload">
            <div class="banner lender_banner fix-top">
                <div class="container">
                    <div class="wrapper">
                        <div class="divider">
                            <div class="text-area">
                                <div v-html="banner_sec.banner_section_heading"></div>
                                <p v-html="banner_sec.banner_section_content"></p>
                                <router-link :to="banner_sec.banner_section_button_url" class="btn-ik yelw-cta">
                                    {{ banner_sec.banner_section_button_name }} <img
                                        src="@/assets/images/icon/arrow_right_blck.svg" alt="">
                                </router-link>
                            </div>
                        </div>
                        <div class="divider">
                            <div class="brn-ser">
                                <img :src="banner_sec.banner_section_right_image" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="streamlined-process">
                <div class="container">
                    <div class="section-head section-head_tow">
                        <!-- <p>{{ sec2_sp.sp_heading }}</p> -->
                        <!-- <h3>{{ sec2_sp.sp_sub_heading }}</h3> -->
                        <h2 v-html="section2_start_lending.start_lending_headline"></h2>
                    </div>
                    
                        <div class="lending-sec">
                           <div class="text-lending-sec">
                                <div class="lending-sec-item" v-for="(row) in section2_start_lending.start_lending_left_content" :key="row">                                 
                                  <div class="text-number">{{row.counter}}</div> 
                                  <div class="lending-sec-item-text">
                                    <h3>{{row.heading}}</h3>
                                    <div v-html="row.content"> </div>
                                  </div>
                                </div>
                               
                              <div class="btn-ylw-left">
                            <a :href="section2_start_lending.start_lending_button_url"  class="explore-btn btn-ik yelw-cta">
                               {{section2_start_lending.start_lending_button_name}} <img src="@/assets/images/icon/arrow_right_blck.svg" alt="Right-arrow">
                            </a>
                        </div>
                           </div> 
                           <div class="image-sec-lending">
                              <img :src="section2_start_lending.start_lending_right_picture" alt="account"/>
                           </div>
                        </div>
                        
                    
                </div>
            </div>

            <div class="global-access">
              <div class="container">
                 <h2 v-html="section3_global_access.global_access_headline"></h2>
                 <div class="global-devider">
                    <div class="text-sec-devider"> 
                        <div v-html="section3_global_access.global_access_left_content"></div>     
                        <a :href="section3_global_access.global_access_landing_button_url" class="explore-btn btn-ik yelw-cta"> {{section3_global_access.global_access_landing_button_name}} <img src="@/assets/images/icon/arrow_right_blck.svg" alt="Right-arrow"></a>                                    
                    </div>
                    
                    <div class="img-devider">
                        <img :src="section3_global_access.global_access_right_picture" alt="Global-Access"/>
                    </div>                    
                 </div>
              </div>
            </div>

            <div class="streamlined-process-bottom">
              <div class="container">
                 <div class="box-total">
                    <div class="box-part" v-for="(row) in section3_global_access.global_access_bottom_section" :key="row" >
                       <img :src="row.picture" alt="Liquidity"/>
                       <h4>{{row.heading}}</h4>
                       <div v-html="row.content"></div>
                    </div>
                   
                 </div>
                 <div class="text-center gap-top"><a :href="section3_global_access.global_access_button_url" class="explore-btn btn-ik yelw-cta"> {{section3_global_access.global_access_button_name}} <img src="@/assets/images/icon/arrow_right_blck.svg" alt="Right-arrow"></a></div>
              </div>
            </div>

        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from "vuex";
import { useMeta } from 'vue-meta'
import { onMounted } from 'vue';
export default {
    name: 'App',
    data() {
        return {
            isload: false,
            //isloading:false,
            banner_sec: '',
            section2_start_lending: '',
            section3_global_access: '',
        }
    },
    mounted() {
        //this.isloading=true;
        //this.GetLender();
    },
    computed: {
        ...mapState({
            lender: state => state.webapiMod.lender
        }),
    },
    watch: {
        lender() {
            this.banner_sec = this.lender.section1_banner_sec;
            this.section2_start_lending = this.lender.section2_start_lending;
            this.section3_global_access = this.lender.section3_global_access;
            this.isload = true;
            //this.isloading=false;
        }
    },
    methods: {
        ...mapActions({
            GetLender: "webapiMod/GetLender",
        }),
    },
    created() {
        if (this.lender.length == 0) {
            this.GetLender();
        } else {
            this.banner_sec = this.lender.section1_banner_sec;
             this.section2_start_lending = this.lender.section2_start_lending;
            this.section3_global_access = this.lender.section3_global_access;
            this.isload = true;
        }
    },
    setup() {
        const { meta } = useMeta({});
        const wpApiUrl = process.env.VUE_APP_API_BASE_URL;
        onMounted(async () => {
            let response = await fetch(wpApiUrl + 'wp/v2/pages?slug=lender');
            let data = await response.json();
            data[0].yoast_head_json.schema = {};
            let dataset = data[0].yoast_head_json;
            dataset.canonical = window.location.href;
            dataset.og_url = window.location.href;
            Object.entries(dataset).forEach(([key, value]) => {
                if (value != '')
                    meta[key] = value;
            });
            // console.log(dataset)
            // console.log(meta)
        });
        return { meta };
    },
};

</script>
