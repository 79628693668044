import { createStore } from "vuex";

import webapiModule from "./modules/webapi";

const store = createStore({
    modules: {
        webapiMod: webapiModule
    }
})

export default store;