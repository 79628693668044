<template>
    <div class="mnht">
        <div v-if="isload">
            <div class="banner the_platform_banner fix-top">
                <div class="container">
                    <div class="wrapper">
                        <div class="divider">
                            <div class="text-area">
                                <h2 v-html="banner_sec.banner_section_heading"></h2>
                                <p v-html="banner_sec.banner_section_content"></p>
                                <router-link :to="banner_sec.banner_section_button_url" class="btn-ik yelw-cta">
                                    {{ banner_sec.banner_section_button_name }} <img
                                        src="@/assets/images/icon/arrow_right_blck.svg" alt="">
                                </router-link>
                            </div>
                        </div>

                        <div class="divider">
                            <div class="brn-ser">
                                <img :src="banner_sec.banner_section_right_image" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="decision-science">
                <div class="container">
                    <div class="section-head section-head_tow">
                        <h3>{{ sec2_ie.heading }}</h3>
                        <p>{{ sec2_ie.sub_heading }} </p>
                    </div>
                    <div class="wrapper">
                        <div class="divider" v-for="(row) in sec2_ie.ai_credit_intelligence_engine" :key="row">
                            <div class="item">
                                <div class="img-set">
                                    <img :src="row.image" alt="">
                                </div>
                                <div class="ttx">
                                    <h3>{{ row.heading }}</h3>
                                    <p>{{ row.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="blockchain-credit">
                <div class="container">
                    <div class="wrapper">
                        <div class="divider">
                            <div class="ttx">
                                <h3 v-html="sec4_blockchain.blockchain_sec_heading"></h3>
                                <ul>
                                    <li v-for="(row) in sec4_blockchain.blockchain_sec_left_content" :key="row">
                                        <div class="iv-img">
                                            <img :src="row.image" alt="">
                                        </div>
                                        <div>
                                            <strong>{{ row.heading }}:</strong>
                                            {{ row.description }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="divider">
                            <div class="aspt-img">
                                <img :src="sec4_blockchain.blockchain_sec_right_image" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="end-ftr-btn">
                <router-link :to="sec4_blockchain.blockchain_sec_button_url" class="btn-ik yelw-cta">
                    {{ sec4_blockchain.blockchain_sec_button_name }}
                    <img src="@/assets/images/icon/arrow_right_blck.svg" alt="" />
                </router-link>
            </div>

        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from "vuex";
import { useMeta } from 'vue-meta'
import { onMounted } from 'vue';

export default {
    name: 'ThePlatform',
    components: {

    },
    data() {
        return {
            isload: false,
            // isloading:false,
            banner_sec: '',
            sec2_ie: '',
            sec4_blockchain: '',

        }
    },
    mounted() {
        // this.isloading=true;
        // this.GetThePlatform();
    },
    computed: {
        ...mapState({
            thePlatform: state => state.webapiMod.thePlatform
        }),
    },
    watch: {
        thePlatform() {
            this.banner_sec = this.thePlatform.section1_banner_sec;
            this.sec2_ie = this.thePlatform.sec2_intelligence_engine;
            this.sec4_blockchain = this.thePlatform.sec4_blockchain;
            this.isload = true;
            // this.isloading=false;
        }
    },
    methods: {
        ...mapActions({
            GetThePlatform: "webapiMod/GetThePlatform",
        }),
    },
    created() {
        if (this.thePlatform.length == 0) {
            this.GetThePlatform();
        } else {
            this.banner_sec = this.thePlatform.section1_banner_sec;
            this.sec2_ie = this.thePlatform.sec2_intelligence_engine;
            this.sec4_blockchain = this.thePlatform.sec4_blockchain;
            this.isload = true;
        }
    },
    setup() {
        const { meta } = useMeta({});
        const wpApiUrl = process.env.VUE_APP_API_BASE_URL;
        onMounted(async () => {
            let response = await fetch(wpApiUrl + 'wp/v2/pages?slug=the-platform');
            let data = await response.json();
            data[0].yoast_head_json.schema = {};
            let dataset = data[0].yoast_head_json;
            dataset.canonical = window.location.href;
            dataset.og_url = window.location.href;
            Object.entries(dataset).forEach(([key, value]) => {
                if (value != '')
                    meta[key] = value;
            });
            // console.log(dataset)
            // console.log(meta)
        });
        return { meta };
    },
};

</script>
