<template>
    <div class="tab-section">
      <ul>
        <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: selectedTab === index }"
      >
        <a href="#" @click.prevent="selectTab(index)">{{ tab }}</a>
      </li>
      </ul>
      <div class="tab-content">
        <div v-for="(tab, index) in tabs" :key="index" v-show="selectedTab === index">
          <slot :name="'tab-' + index"></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      tabs: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        selectedTab: 0,
      };
    },
    methods: {
      selectTab(index) {
        this.selectedTab = index;
      },
    },
  };
  </script>
  
  <style>

.tab-section ul{
  display: flex;
  justify-content: center;
  margin-top:70px;
}
  .tab-section li {
    display: inline;
    margin-right: 10px;
  }

  .tab-section li.active a{
    background: #239CFF;
    color:#fff;
    border-color:#239CFF;
  }
  

  .tab-section li a{
    border: solid 1px #000000;
    border-radius: 5px;
    padding: 0 35px;
    display: inline-block;
    font-size:16px;
    line-height: 50px;
    text-decoration: none;
    color:#000000;
    
  }

  .tab-section li:hover a{
    background: #239CFF;
    color:#fff;
    border-color:#239CFF;
  }
 
  /* .tab-content div {
    display: none;
  } */
  .tab-content div[v-show="true"] {
    display: block;
  }
  </style>
  