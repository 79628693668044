<template>
    <div class="contact-page fix-top">
        <div class="container">
            <div class="contect-area">
                <div class="wrapper">
                    <div class="divider">
                        <div class="title">
                            <h2>Contact Us </h2>
                            <p>Fill in your details – one of our experts will contact you.</p>
                        </div>
                        <form action="" @submit.prevent="submit_form">
                            <div class="frm-wapr">
                                <div>
                                    <label class="required">I am a:</label>
                                    <select name="" id="" :class="{error:this.errors.user_type}" @change="handleValidate('user_type')" v-model="c_form.user_type">
                                        <option value="" selected>Select One</option>
                                        <option value="Borrower">Borrower</option>
                                        <option value="Lender">Lender</option>
                                    </select>
                                    <p class="form-error" v-if="this.errors.user_type">
                                        {{ this.errors.user_type }}
                                    </p>
                                    
                                </div>
                                <div class="dird">
                                    <label>First name</label>
                                    <input type="text" placeholder="Please enter first name" v-model="c_form.f_name">
                                </div>
                                <div class="dird">
                                    <label>Last name</label>
                                    <input type="text" placeholder="Please enter last name" v-model="c_form.l_name">
                                </div>
                                <div class="dird">
                                    <label>Company</label>
                                    <input type="text" placeholder="Please enter company name" v-model="c_form.company">
                                </div>
                                <div class="dird">
                                    <label>Title</label>
                                    <input type="text" placeholder="Please enter title" v-model="c_form.title">
                                </div>
                                <div class="dird">
                                    <label class="required">Business email </label>
                                    <input type="text" :class="{error:this.errors.email}" @keyup="handleValidate('email')" placeholder="Please enter email" v-model="c_form.email" >
                                    <p class="form-error" v-if="this.errors.email">
                                        {{ this.errors.email }}
                                    </p>
                                </div>
                                <div class="dird">
                                    <label class="required">Business phone</label>
                                    <input type="text" :class="{error:this.errors.phone}" @keyup="handleValidate('phone')" @input="acceptNumber" placeholder="Please enter 10 digit number" v-model="c_form.phone" >
                                    <p class="form-error" v-if="this.errors.phone">
                                        {{ this.errors.phone }}
                                    </p>
                                </div>
                                <div>
                                    <label>Message</label>
                                    <textarea v-model="c_form.message" placeholder="Enter your message"></textarea>
                                </div>
                                <div>
                                    <label class="check-lab required">
                                        <input type="checkbox" name=""  @click="handleValidate('agree')" id="" v-model="c_form.agree">
                                        By clicking submit, you agree with our
                                        <router-link to='/privacy-policy'>Privacy Policy.</router-link>
                                    </label>
                                    <p class="form-error" v-if="this.errors.privacy">
                                        {{ this.errors.privacy }}
                                    </p>
                                </div>
                                <div>
                                    <button type="submit" :class="cntbtn" :disabled="submitting">{{submitBtn}} <img v-if="!submitting" src="@/assets/images/icon/arrow_right_blck.svg" alt=""></button>
                                </div>
                            </div>
                        </form>
                        <!-- <p style="color:#2551da">{{ success_msg }}</p> -->
                    </div>
                    <div class="divider">
                        <div class="ipg-srt">
                            <!-- <img src="@/assets/images/cont-mapw.png" alt=""> -->
                            <img src="@/assets/images/contact-us-banner.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2'
import { useMeta } from 'vue-meta'
// import { onMounted } from 'vue';

export default {
    name: 'ContactUs',
    data() {
        return {
            
            errors: {
                user_type: "",
                privacy: false,
                phone: "",
                email: "",
            },
            c_form: {
                user_type:'',
                f_name:'',
                l_name:'',
                company:'',
                title:'',
                email: '',
                phone: '',
                message: '',
                agree: false,
            },
            success_msg: '',
            // cntbtn: 'btn-ik yelw-cta',
            cntbtn: 'btn-ik yelw-cta',
            submitBtn: 'Submit',
            submitBtnClicked: false,
            submitting: false,
        }
    },
    computed: {
        ...mapState({
            contactFormSmbtData: state => state.AppListModule.contactFormSmbtData,
        }),
    },
    watch: {
        email(value){
        // binding this to the data value in the email input
        this.c_form.email = value;
            this.validateEmail(value);
        }
        
    },
    methods: {
        ...mapActions({
            ContactFormSmbt: "AppListModule/ContactFormSmbt",
        }),
        acceptNumber() {
            let x = this.c_form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.c_form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        handleValidate(ev) {
            // console.log(this.submitBtnClicked);
            if (this.submitBtnClicked) {
                if (ev == 'email') {
                    console.log("email check");
                    // let validNamePattern = new RegExp(/^[^@]+@\w+(\.\w+)+\w$/);
                    let validNamePattern = new RegExp("^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)+(.[a-z]{2,4})$");
    
                    if (validNamePattern.test(this.c_form.email)) {
                        this.errors.email = '';
                    } else if(!this.c_form.email){
                        this.errors.email = 'Email is required!';
                    } else {
                        this.errors.email = 'Please enter valid email!';
                    } 
                }
                if(ev == 'phone'){
                    if(!this.c_form.phone){
                        this.errors.phone = 'Enter Business phone';
                    } else if(this.c_form.phone.length < 14){
                        this.errors.phone = 'Please enter 10 digit number?';
                    } else {
                        this.errors.phone = '';
                    }
                }
                if(ev == 'user_type'){
                    if(!this.c_form.user_type){
                        this.errors.user_type = 'Please select an option!';
                    } else {
                        this.errors.user_type = '';
                    }
                }
                if (ev == 'agree') {
                    if(this.c_form.agree){
                        this.errors.privacy = 'Please accept the privacy policy!';
                    } else {
                        this.c_form.agree = true;
                        this.errors.privacy = '';
                    }
                    console.log(this.c_form);
                }
            }
        },
        submit_form() {
            this.submitBtnClicked = true;
            if (!this.c_form.user_type || !this.c_form.agree || !this.c_form.phone || !this.c_form.email) {
                if (!this.c_form.user_type) {
                    window.scrollTo({
                        top: 150,
                        left: 0,
                        behavior: 'smooth'
                    });
                    this.errors.user_type = 'Please choose an option!';

                }
                // if (!this.c_form.email) {
                // }
                this.handleValidate('email')
                this.handleValidate('phone')
                // if (!this.c_form.phone) {
                //     // this.errors.phone = 'Phone number is required!';
                // }
                if (!this.c_form.agree) {
                    this.errors.privacy = 'Please accept the privacy policy!';

                }
            } else if (!this.errors.user_type && !this.errors.privacy && !this.errors.phone && !this.errors.email) {
                this.submitting = true;
                this.submitBtn = 'Sending...';
                this.cntbtn = 'btn-ik yelw-cta contactbtn';
                // console.log(this.c_form);
                const getdata = {
                    select_bl: this.c_form.user_type,
                    first_name: this.c_form.f_name,
                    last_name: this.c_form.l_name,
                    business_email: this.c_form.email,
                    business_phone: this.c_form.phone,
                    company: this.c_form.company,
                    title: this.c_form.title,
                    message: this.c_form.message,
                    checkbox: this.c_form.agree,

                };
                // this.$axios.post(this.$apiUrl + "mail-contact-form", this.c_form)
                this.$axios.post(this.$wpApiUrl + "wp/v2/contact_form_submit_data", getdata)
                .then((res) => {
                    this.c_form.user_type = "";
                    this.c_form.f_name = "";
                    this.c_form.l_name = "";
                    this.c_form.company = "";
                    this.c_form.title = "";
                    this.c_form.email = "";
                    this.c_form.phone = "";
                    this.c_form.message = "";
                    this.c_form.agree = false;
                    if (res.data.status == "success") {
                        Swal.fire({
                            title: 'Done',
                            text: res.data.msg,
                            icon: 'success',
                            timer: 1500,
                            showConfirmButton: false
                        })
                    } else {
                        Swal.fire({
                            title: 'OPPS',
                            text: res.data.msg,
                            icon: 'warning',
                        });
                    }


                    this.success_msg = res.data.message;
                    console.log(res.data);
                    this.submitBtn = 'Submit';
                    this.submit = false;
                    this.cntbtn = 'btn-ik yelw-cta';
                    this.submitting = false;
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire({
                        title: 'OPPS',
                        text: 'Error in submitting form!',
                        icon: 'warning',
                    });
                    this.submitting = false;
                });
            }

            
        },

    },
    
    mounted() {
        // setInterval(() => {
        //     this.submitting = true;
        // }, 2000);
        console.log('Contact Us Page');

        
    },
    created() {
        // this.getPostData();
    },
    setup() {
        const { meta } = useMeta({});
        meta.title = 'Contact Us'
        meta.description = 'Welcome to contact us page. feel free to contact us by submitting this form'
        // onMounted(async () => {
        //     let response = await fetch('https://ricoman.bigwavedevelopment.com/back-end/wp-json/wp/v2/pages?slug=home-page');
        //     let data = await response.json();
        //     data[0].yoast_head_json.schema = {};
        //     let dataset = data[0].yoast_head_json;
        //     Object.entries(dataset).forEach(([key, value]) => {
        //         if (value != '')
        //             meta[key] = value;
        //     });
        //     console.log(dataset)
        //     console.log(meta)
        // });
        return { meta };
    },
}
</script>