const mutations = {
    increment(state) {
        state.count++;
    },
    updatePostData(state, postData) {
        console.log('test - updatePostData - ', postData);
        state.postData = postData;
        state.count = 110;
    },
    SET_MENU_DATA: (state, menuData) => {
        state.menuData = menuData;
    },

    SET_CONTACTUS_MENU: (state, contactUsMenu) => {
        state.contactUsMenu = contactUsMenu;
    },

    SET_THEME_SETTINGS_DATA: (state, themeSettingsData) => {
        state.themeSettingsData = themeSettingsData;
    },
    
    SET_HOME: (state, home) => {
        state.home = home;
    },

    SET_FOOTER_MENU_DATA: (state, footerMenuData) => {
        state.footerMenuData = footerMenuData;
    },

    SET_FOOTER_MENU2_DATA: (state, footerMenu2Data) => {
        state.footerMenu2Data = footerMenu2Data;
    },
    SET_FOOTER_MENU3_DATA: (state, footerMenu3Data) => {
        state.footerMenu3Data = footerMenu3Data;
    },

    SET_HEADER_MENU_DATA: (state, headerMenuData) => {
        state.headerMenuData = headerMenuData;
    },

    SET_CONTACT_FORM_SMBT_RESP (state, contactFormSmbtData) {
        state.contactFormSmbtData = contactFormSmbtData;
    },

    SET_ABOUTUS: (state, aboutus) => {
        state.aboutus = aboutus;
    },

    SET_THE_PLATFORM: (state, thePlatform) => {
        state.thePlatform = thePlatform;
    },

    SET_LANDER: (state, lender) => {
        state.lender = lender;
    },

    SET_BORROW: (state, borrow) => {
        state.borrow = borrow;
    },
    SET_PRIVACY_POLICY: (state, privacyPolicy) => {
        state.privacyPolicy.content = privacyPolicy;
        state.privacyPolicy.getDataStatus = false;
    },
}

export default mutations;