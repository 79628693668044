<template>
    <div>
        <div class="banner the_platform_banner fix-top">
            <div class="container">
                <div class="wrapper" v-if="banner_sec">
                    <div class="divider">
                        <div class="text-area">
                            <h2 v-html="banner_sec.banner_section_heading"></h2>
                            <p v-html="banner_sec.banner_section_content"></p>
                            <a :href="banner_sec.banner_section_button_url" class="btn-ik yelw-cta">
                                {{banner_sec.banner_section_button_name}} <img
                                    src="@/assets/images/icon/arrow_right_blck.svg" alt="">
                            </a>
                        </div>
                    </div>

                    <div class="divider">
                        <div class="brn-ser">
                            <img :src="banner_sec.banner_section_right_image" alt="Provide">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="streamlined-process" v-if="section2_credit_pools">
            <div class="container">
            <h2 class="text-center">{{section2_credit_pools.credit_pools_headline}}</h2> 
            <div class="access-sec gap-top">
                <div class="access-text">
                    <div class="access-item" v-for="(row) in section2_credit_pools.credit_pools_left_section" :key="row">
                        
                        <div class="access-item-img"><img :src="row.picture" alt="Debt"/></div>
                        <div class="acess-item-text">
                            <h3> {{row.heading}}</h3>
                            <div v-html="row.content"></div>
                        </div>
                    </div>
                
                </div>
                <div class="acess-graph">
                    <img :src="section2_credit_pools.credit_pools_right_picture" alt="democratize"/>
                </div>
            </div>
            </div>
        </div>

        <div class="container meets-ai" v-if="section3_meet_ai">
            <h2 v-html="section3_meet_ai.meets_ai_headline"></h2>
            <h5 v-html="section3_meet_ai.meets_ai_content" > </h5>
            <div class="box-total gap-top">
            <div class="box-part" v-for="(row) in section3_meet_ai.meets_ai_features" :key="row">
                <img :src="row.picture" alt="Platform"/>
                 <h4>{{row.heading}}</h4>
                <div v-html="row.content"></div>
            </div>

            </div>
            <div class="text-center gap-top"><a :href="section3_meet_ai.meets_ai_button_url" class="explore-btn btn-ik yelw-cta"> {{section3_meet_ai.meets_ai_button_name}} <img src="@/assets/images/icon/arrow_right_blck.svg" alt="Right-arrow"></a></div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from "vuex";
import { useMeta } from 'vue-meta'
import { onMounted } from 'vue';

export default {
    name: 'ProtocolPage',
    components: {

    },
    data() {
        return {
            isload: false,
            // isloading:false,
            banner_sec: '',
            section2_credit_pools: '',
            section3_meet_ai: '',
        }
    },
    mounted() {
        // this.isloading=true;
        this.GetThePlatform();
    },
    computed: {
        ...mapState({
            thePlatform: state => state.webapiMod.thePlatform
        }),
    },
    watch: {
        thePlatform() {
            this.banner_sec = this.thePlatform.section1_banner_sec;
            this.section2_credit_pools = this.thePlatform.section2_credit_pools;
            this.section3_meet_ai = this.thePlatform.section3_meet_ai;
            this.isload = true;
            // this.isloading=false;
        }
    },
    methods: {
        ...mapActions({
            GetThePlatform: "webapiMod/GetThePlatform",
        }),
    },
    created() {
        if (this.thePlatform.length == 0) {
            this.GetThePlatform();
        } else {
            this.banner_sec = this.thePlatform.section1_banner_sec;
            this.sec2_ie = this.thePlatform.section2_credit_pools;
            this.sec4_blockchain = this.thePlatform.section3_meet_ai;
            this.isload = true;
        }
    },
    setup() {
        const { meta } = useMeta({});
        const wpApiUrl = process.env.VUE_APP_API_BASE_URL;
        onMounted(async () => {
            let response = await fetch(wpApiUrl + 'wp/v2/pages?slug=the-platform');
            let data = await response.json();
            data[0].yoast_head_json.schema = {};
            let dataset = data[0].yoast_head_json;
            dataset.canonical = window.location.href;
            dataset.og_url = window.location.href;
            Object.entries(dataset).forEach(([key, value]) => {
                if (value != '')
                    meta[key] = value;
            });
            // console.log(dataset)
            // console.log(meta)
        });
        return { meta };
    },
};

</script>
