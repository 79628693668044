import axios from 'axios';
// import Swal from 'sweetalert2'
// import router from '@/routes'

const wpApiUrl = process.env.VUE_APP_API_BASE_URL;

const actions = {
    // getPostDataFromApi(context, ndata) {
    //     console.log(ndata);
    //     axios.get('https://jsonplaceholder.typicode.com/posts/1')
    //     .then((res) => {
    //         context.commit('updatePostData', res.data)
    //     })
    //     .catch(err => console.log(err));
    // },
    // resetPostData(context) {
    //     context.commit('updatePostData', null);
    // },

    GetHeaderMenuData (store, params) {
        axios.get(wpApiUrl + "wp/v2/get_menu_data", { params }).then((resp) => {
            //console.log(resp.data.status);
            store.commit('SET_HEADER_MENU_DATA', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    GetContactUsMenu (store, params) {
        axios.get(wpApiUrl + "wp/v2/get_menu_data", { params }).then((resp) => {
            store.commit('SET_CONTACTUS_MENU', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    GetFooterMenuData (store, params) {
        axios.get(wpApiUrl + "wp/v2/get_menu_data", { params }).then((resp) => {
            store.commit('SET_FOOTER_MENU_DATA', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    GetThemeSettingsData (store, params) {
        axios.get(wpApiUrl + "wp/v2/get_theme_settings_data", { params }).then((resp) => {
            store.commit('SET_THEME_SETTINGS_DATA', resp.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    GetThemeSettingsAndMenuData(store) {
        axios.get(wpApiUrl + "wp/v2/get_theme_settings_and_menu_data").then((resp) => {
            console.log(resp.data.theme_settings_data);
            console.log(resp.data.menu_data);
            store.commit('SET_THEME_SETTINGS_DATA', resp.data.theme_settings_data);
            store.commit('SET_HEADER_MENU_DATA', resp.data.menu_data.header_menu_data);
            store.commit('SET_FOOTER_MENU_DATA', resp.data.menu_data.footer_menu_data);
            store.commit('SET_FOOTER_MENU2_DATA', resp.data.menu_data.footer_menu2_data);
            store.commit('SET_FOOTER_MENU3_DATA', resp.data.menu_data.footer_menu3_data);

           store.commit('SET_CONTACTUS_MENU', resp.data.menu_data.contactus_menu_data);
        }).catch((err) => {
            console.log(err);
        });
    },
    
    GetHome(store, params) {
        axios.get(wpApiUrl + "wp/v2/get_home_data", { params })
            .then((resp) => {
                console.log(resp.data);
                store.commit("SET_HOME", resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },

    GetAboutUs(store, params) {
        axios.get(wpApiUrl + "wp/v2/get_aboutus_data", { params })
            .then((resp) => {
                store.commit("SET_ABOUTUS", resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },

    // ContactFormSmbt (store, params) {
    //     axios.post(wpApiUrl + "wp/v2/contact_form_submit_data", params).then((resp) => {
    //         store.commit('SET_CONTACT_FORM_SMBT_RESP', resp.data);
    //         console.log(resp.data);
    //         if(resp.data.status == "success"){
    //             Swal.fire({
    //                 title: 'Done',
    //                 text:  resp.data.msg,
    //                 icon: 'success',
    //                 timer: 1500,
    //                 showConfirmButton: false
    //             }).then(function() {
    //                 router.push({ name: 'ContactUs' });
    //             });
    //         }else{
    //             Swal.fire({
    //                 title: 'OPPS',
    //                 text:  resp.data.msg,
    //                 icon: 'warning',
    //             });
    //         }
    //     }).catch((err) => {
    //         console.log(err);
    //         Swal.fire({
    //             title: 'OPPS',
    //             text:   'Error in submitting form!',
    //             icon: 'warning',
                
    //         });
    //     });
    // },
    // ContactFormSmbtOld (store, params) {
    //     const promise= new Promise(function(resolve) {
    //         axios.post(wpApiUrl + "wp/v2/contact_form_submit_data", params).then((resp) => {
    //             store.commit('SET_CONTACT_FORM_SMBT_RESP', resp.data);
    //             console.log(resp.data);
    //             resolve(resp.data);
    //         }).catch((err) => {
    //             console.log(err);
                
    //         });
    //     });
    //     promise.then((successMessage) => {
    //         //console.log(successMessage);
    //         if(successMessage.status == "success"){
    //             Swal.fire({
    //                 title: 'Done',
    //                 text:  successMessage.msg,
    //                 icon: 'success',
    //                 timer: 1500,
    //                 showConfirmButton: false
    //             }).then(function() {
    //                 router.push({ name: 'ContactUs' });
    //             });
    //         }else{
    //             Swal.fire({
    //                 title: 'OPPS',
    //                 text:  successMessage.msg,
    //                 icon: 'warning',
    //             });
    //         }
            
    //     }, error => {
    //         Swal.fire({
    //             title: 'OPPS',
    //             text:   'Error in submitting form!',
    //             icon: 'warning',
                
    //         });
    //         console.error(error);

    //     });
    // },

    GetThePlatform(store, params) {
        axios.get(wpApiUrl + "wp/v2/get_protocol_data", { params })
            .then((resp) => {
                //console.log(resp.data);
                store.commit("SET_THE_PLATFORM", resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },

    GetLender(store, params) {
        axios.get(wpApiUrl + "wp/v2/get_lender_data", { params })
            .then((resp) => {
                //console.log(resp.data);
                store.commit("SET_LANDER", resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    
    GetBorrow(store, params) {
        axios.get(wpApiUrl + "wp/v2/get_borrow_data", { params })
            .then((resp) => {
                //console.log(resp.data);
                store.commit("SET_BORROW", resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    },
    GetPrivacyPolicy(store, params) {
        // console.log('GetPrivacyPolicy', store, params);
        axios.get(wpApiUrl + "wp/v2/get_privacy_policy_data", { params })
            .then((resp) => {
                console.log(resp.data.privacy_policy_data);
                store.commit("SET_PRIVACY_POLICY", resp.data.privacy_policy_data);
            })
            .catch((err) => {
                console.log(err);
            });
    },
}

export default actions;