const state = {
    count: 110,
    person: {
        f_name: 'First Name',
        l_name: 'Last Name'
    },
    postData: null,

    menuData: [],
    contactUsMenu: {
        "131":{
            "ID": 131,
            "post_author": "1",
            "post_date": "2024-05-02 05:46:15",
            "post_date_gmt": "2023-05-16 12:40:11",
            "post_content": "",
            "post_title": "Launch App",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "contact-us",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2024-05-02 05:46:15",
            "post_modified_gmt": "2024-05-02 05:46:15",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=131",
            "menu_order": 1,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 131,
            "menu_item_parent": "0",
            "object_id": "131",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Launch App",
            "url": "https://app.csigma.finance",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
                ""
            ],
            "xfn": ""
        }
    },
    themeSettingsData: {
        "site_logo": "https://csigma.finance/dash/wp-content/uploads/2023/05/logo.351d9dae.png",
        "footer_about_description": "Our mission is to transform access to the capital markets by leveraging technology, data, machine learning, and blockchain. We also strive to provide vetted institutional-grade credit opportunities to lenders of all sizes in a frictionless process.",
        "twitter_link": "",
        "linkedin_link": "",
        // "facebook_link": "",
        // "youtube_link": "",
        // "instagram_link": "",
        "footer_copyright_section": "© 2022 cSigma - All rights Reserved"
    },
    home: {
        "section1_banner_sec": {
            "banner_section_heading": "<h2>We Make Private <span style=\"color: #168def;\">Debt Capital</span> Markets a Science</h2>\n",
            "banner_section_content": "<p>A single platform that combines private debt capital markets,<strong> blockchain capital access and AI-powered portfolio intelligence.</strong></p>\n",
            "banner_section_button_name": "Contact Us ",
            "banner_section_button_url": "/contactUs",
            "banner_section_right_image": require('@/assets/images/banner-img.png')
        },
        "section2_AI_powered": {
            "ai_powered_title": "Machine learning-powered ",
            "ai_powered_heading": "<h3>Capital markets <span style=\"color: #083d6c;\">platform</span></h3>\n",
            "ai_powered_left_content": "<p><strong>AI </strong>and <strong>Blockchain platform</strong> that brings certainty and<strong> global access</strong> to the debt value chain</p>\n",
            "ai_powered_left_button_name": "Learn More",
            "ai_powered_left_button_url": "/the-platform",
            "ai_powered_right_content": [
            {
                "image": require('@/assets/images/icon/grahp-down.svg'),
                "heading": "Faster capital access & deployment:",
                "content": "Thru a standardized marketplace of data, agreements, borrowers and lenders."
            },
            {
                "image": require('@/assets/images/icon/grid-pgm.svg'),
                "heading": "Generate higher yield:",
                "content": "Use rich data intelligence to yield better returns on a risk adjusted basis."
            },
            {
                "image": require('@/assets/images/icon/onito.svg'),
                "heading": "Monitor capital 24/7:",
                "content": "Risk Intelligence provides real-time reporting with always-on digital credit monitoring."
            }
            ]
        },
        "section3_lb": {
            "lender_and_borrower": [
            {
                "button_title": "Lender",
                "heading": "<h2>Higher risk-adjusted yields with <span style=\"color: #168def;\">AI Debt</span> co-pilot</h2>\n",
                "content": "Source, analyze and price private credit on the platform -- Let co-pilot monitor risks with real-time data and advanced analytics.\r\n",
                "button_name": "Learn More ",
                "button_url": "/lender"
            },
            {
                "button_title": "Borrower",
                "heading": "<h2>Democratized <span style=\"color: #168def;\">access to institutional</span>-grade credit</h2>\n",
                "content": "cSigma reduces the frictions in private credit lending opening the door to institutional-grade credit to a broader range of borrowers.",
                "button_name": "Learn More",
                "button_url": "/borrow"
            }
            ],
            "lender_and_borrower_title": "Trusted by Growing Businesses",
            "lender_and_borrower_logo": [
            {
                "logo": require('@/assets/images/bluex.png')
            },
            {
                "logo": require('@/assets/images/BBMSL.png')
            }
            ]
        },
        "section4_cmp": {
            "capital_markets_platform_heading": "A Capital Markets Platform to 10X Your Capabilities ",
            "capital_markets_platform_lender_and_borrower": [
            {
                "image": "https://csigma.finance/dash/wp-content/uploads/2023/05/ukn-shap-1.png",
                "button_title": " Lender ",
                "heading": "<p>Access <span style=\"color: #168def;\">10x more deal flow</span> for your <strong>Capital Markets</strong> team.</p>\n<p>&nbsp;</p>\n",
                "button_name": "Learn More",
                "button_url": "/lender"
            },
            {
                "image": "https://csigma.finance/dash/wp-content/uploads/2023/05/ukn-shap-2-1.png",
                "button_title": "Borrower",
                "heading": "<p>Reduce the Cost of <span style=\"color: #168def;\">Debt Raising</span> by <strong>up to 50%.</strong></p>\n<p>&nbsp;</p>\n",
                "button_name": "Learn More",
                "button_url": "/borrow"
            }
            ],
            "capital_markets_platform_button_name": "Get Started with cSigma",
            "capital_markets_platform_button_url": "/contactUs"
        }
    },
    footerMenuData : {
        "127": {
            "ID": 127,
            "post_author": "1",
            "post_date": "2023-05-31 04:52:36",
            "post_date_gmt": "2023-05-16 03:55:12",
            "post_content": "",
            "post_title": "The Platform",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "the-platform-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-31 04:52:36",
            "post_modified_gmt": "2023-05-31 04:52:36",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=127",
            "menu_order": 1,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 127,
            "menu_item_parent": "0",
            "object_id": "127",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "The Platform",
            "url": "/the-platform",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        },
        "411": {
            "ID": 411,
            "post_author": "1",
            "post_date": "2023-05-31 04:52:36",
            "post_date_gmt": "2023-05-30 10:12:22",
            "post_content": "",
            "post_title": "Borrow",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "borrow-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-31 04:52:36",
            "post_modified_gmt": "2023-05-31 04:52:36",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=411",
            "menu_order": 5,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 411,
            "menu_item_parent": "0",
            "object_id": "411",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Borrow",
            "url": "/borrow",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        },
        "412": {
            "ID": 412,
            "post_author": "1",
            "post_date": "2023-05-31 04:52:36",
            "post_date_gmt": "2023-05-30 10:12:22",
            "post_content": "",
            "post_title": "About Us",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "about-us-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-31 04:52:36",
            "post_modified_gmt": "2023-05-31 04:52:36",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=412",
            "menu_order": 3,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 412,
            "menu_item_parent": "0",
            "object_id": "412",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "About Us",
            "url": "/about-us",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        },
        "413": {
            "ID": 413,
            "post_author": "1",
            "post_date": "2023-05-31 04:52:36",
            "post_date_gmt": "2023-05-30 10:12:22",
            "post_content": "",
            "post_title": "Contact Us",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "contact-us-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-31 04:52:36",
            "post_modified_gmt": "2023-05-31 04:52:36",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=413",
            "menu_order": 4,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 413,
            "menu_item_parent": "0",
            "object_id": "413",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Contact Us",
            "url": "/contactUs",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        },
        "414": {
            "ID": 414,
            "post_author": "1",
            "post_date": "2023-05-31 04:52:36",
            "post_date_gmt": "2023-05-30 10:12:22",
            "post_content": "",
            "post_title": "Lend",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "lend-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-31 04:52:36",
            "post_modified_gmt": "2023-05-31 04:52:36",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=414",
            "menu_order": 2,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 414,
            "menu_item_parent": "0",
            "object_id": "414",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Lend",
            "url": "/lender",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        },
        "415": {
            "ID": 415,
            "post_author": "1",
            "post_date": "2023-05-31 04:52:36",
            "post_date_gmt": "2023-05-30 10:12:22",
            "post_content": "",
            "post_title": "Privacy Policy",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "privacy-policy",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-31 04:52:36",
            "post_modified_gmt": "2023-05-31 04:52:36",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=415",
            "menu_order": 6,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 415,
            "menu_item_parent": "0",
            "object_id": "415",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Privacy Policy",
            "url": "/privacy-policy",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        }
    },    footerMenu2Data : {
        "872": {
            "ID": 872,
            "post_author": "1",
            "post_date": "2024-06-24 09:56:06",
            "post_date_gmt": "2024-06-24 07:06:32",
            "post_content": "",
            "post_title": "Whitepaper",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "whitepaper-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2024-06-24 09:56:06",
            "post_modified_gmt": "2024-06-24 09:56:06",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://demo.bigwavedevelopment.com/cSigma/admin/?p=872",
            "menu_order": 1,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 872,
            "menu_item_parent": "0",
            "object_id": "872",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Whitepaper",
            "url": "https://certificate.immunebytes.com/pdf/cSigma/",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
                ""
            ],
            "xfn": ""
        },
        "874": {
            "ID": 874,
            "post_author": "1",
            "post_date": "2024-06-24 09:56:06",
            "post_date_gmt": "2024-06-24 07:06:32",
            "post_content": "",
            "post_title": "Audits",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "audits-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2024-06-24 09:56:06",
            "post_modified_gmt": "2024-06-24 09:56:06",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://demo.bigwavedevelopment.com/cSigma/admin/?p=874",
            "menu_order": 2,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 874,
            "menu_item_parent": "0",
            "object_id": "874",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Audits",
            "url": "#",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
                ""
            ],
            "xfn": ""
        },
        "875": {
            "ID": 875,
            "post_author": "1",
            "post_date": "2024-06-24 09:56:06",
            "post_date_gmt": "2024-06-24 07:06:32",
            "post_content": "",
            "post_title": "Help docs",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "help-docs-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2024-06-24 09:56:06",
            "post_modified_gmt": "2024-06-24 09:56:06",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://demo.bigwavedevelopment.com/cSigma/admin/?p=875",
            "menu_order": 3,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 875,
            "menu_item_parent": "0",
            "object_id": "875",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Help docs",
            "url": "#",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
                ""
            ],
            "xfn": ""
        },
        "876": {
            "ID": 876,
            "post_author": "1",
            "post_date": "2024-06-24 09:56:06",
            "post_date_gmt": "2024-06-24 07:06:32",
            "post_content": "",
            "post_title": "Blog",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "blog-2",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2024-06-24 09:56:06",
            "post_modified_gmt": "2024-06-24 09:56:06",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://demo.bigwavedevelopment.com/cSigma/admin/?p=876",
            "menu_order": 4,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 876,
            "menu_item_parent": "0",
            "object_id": "876",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Blog",
            "url": "https://csigma.medium.com/",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
                ""
            ],
            "xfn": ""
        }
    },
    footerMenu3Data : {
        "877": {
            "ID": 877,
            "post_author": "1",
            "post_date": "2024-06-24 07:23:19",
            "post_date_gmt": "2024-06-24 07:07:23",
            "post_content": "",
            "post_title": "About Us",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "about-us-3",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2024-06-24 07:23:19",
            "post_modified_gmt": "2024-06-24 07:23:19",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://demo.bigwavedevelopment.com/cSigma/admin/?p=877",
            "menu_order": 1,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 877,
            "menu_item_parent": "0",
            "object_id": "877",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "About Us",
            "url": "/about-us",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
                ""
            ],
            "xfn": ""
        },
        "878": {
            "ID": 878,
            "post_author": "1",
            "post_date": "2024-06-24 07:23:19",
            "post_date_gmt": "2024-06-24 07:07:23",
            "post_content": "",
            "post_title": "Contact Us",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "contact-us-3",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2024-06-24 07:23:19",
            "post_modified_gmt": "2024-06-24 07:23:19",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://demo.bigwavedevelopment.com/cSigma/admin/?p=878",
            "menu_order": 2,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 878,
            "menu_item_parent": "0",
            "object_id": "878",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Contact Us",
            "url": "/contactUs",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
                ""
            ],
            "xfn": ""
        }
    },


    headerMenuData : {
      
        "119": {
            "ID": 119,
            "post_author": "1",
            "post_date": "2023-05-26 11:04:38",
            "post_date_gmt": "2023-05-15 11:14:49",
            "post_content": "",
            "post_title": "Lend",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "lend",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-26 11:04:38",
            "post_modified_gmt": "2023-05-26 11:04:38",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=119",
            "menu_order": 2,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 119,
            "menu_item_parent": "0",
            "object_id": "119",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Lend",
            "url": "/lender",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        },
        "120": {
            "ID": 120,
            "post_author": "1",
            "post_date": "2023-05-26 11:04:38",
            "post_date_gmt": "2023-05-15 11:14:49",
            "post_content": "",
            "post_title": "Borrow",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "borrow",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-26 11:04:38",
            "post_modified_gmt": "2023-05-26 11:04:38",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=120",
            "menu_order": 3,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 120,
            "menu_item_parent": "0",
            "object_id": "120",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Borrow",
            "url": "/borrow",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        },
        "121": {
            "ID": 121,
            "post_author": "1",
            "post_date": "2023-05-26 11:04:38",
            "post_date_gmt": "2023-05-15 11:14:49",
            "post_content": "",
            "post_title": "Protocol",
            "post_excerpt": "",
            "post_status": "publish",
            "comment_status": "closed",
            "ping_status": "closed",
            "post_password": "",
            "post_name": "protocol",
            "to_ping": "",
            "pinged": "",
            "post_modified": "2023-05-26 11:04:38",
            "post_modified_gmt": "2023-05-26 11:04:38",
            "post_content_filtered": "",
            "post_parent": 0,
            "guid": "https://csigma.finance/dash/?p=121",
            "menu_order": 3,
            "post_type": "nav_menu_item",
            "post_mime_type": "",
            "comment_count": "0",
            "filter": "raw",
            "db_id": 120,
            "menu_item_parent": "0",
            "object_id": "120",
            "object": "custom",
            "type": "custom",
            "type_label": "Custom Link",
            "title": "Protocol",
            "url": "/protocol",
            "target": "",
            "attr_title": "",
            "description": "",
            "classes": [
            ""
            ],
            "xfn": ""
        },
        },
    contactFormSmbtData: [],
    aboutus: [],
    thePlatform: [],
    lender: [],
    borrow: [],
    privacyPolicy: {
        getDataStatus: true,
        content_new: `<h1>Privacy Policy for cSigma Finance Inc</h1>
            <p>At cSigma Finance Inc, accessible from csigma.finance, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by cSigma Finance Inc and how we use it.</p>
            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
            <h2>Log Files</h2>
            <p>cSigma Finance Inc follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the <a href="https://www.termsfeed.com/privacy-policy-generator/" target="_blank">Privacy Policy Generator</a>.</p>
            <h2>Privacy Policies</h2>
            <P>You may consult this list to find the Privacy Policy for each of the advertising partners of cSigma Finance Inc.</p>
            <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on cSigma Finance Inc, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
            <p>Note that cSigma Finance Inc has no access to or control over these cookies that are used by third-party advertisers.</p>
            <h2>Third Party Privacy Policies</h2>
            <p>cSigma Finance Inc's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>
            <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>
            <h2>Children's Information</h2>
            <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
            <p>cSigma Finance Inc does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
            <h2>Online Privacy Policy Only</h2>
            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in cSigma Finance Inc. This policy is not applicable to any information collected offline or via channels other than this website.</p>
            <h2>Consent</h2>
            <p>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>`
    }
}

export default state;