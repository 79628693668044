<template>
    <div class="mnht">
        <div v-if="isload">
            <div class="banner banner_borrow fix-top banner-white">
                <div class="container">
                    <div class="wrapper">
                        <div class="divider">
                            <div class="text-area">
                                <h2 v-html="banner_sec.banner_section_heading"></h2>
                                <h6 v-html="banner_sec.banner_section_content"></h6>
                                <p v-html="banner_sec.banner_small_heading"></p>
                                <router-link :to="banner_sec.banner_section_button_url" class="btn-ik yelw-cta">
                                    {{ banner_sec.banner_section_button_name }} <img
                                        src="@/assets/images/icon/arrow_right_blck.svg" alt="">
                                </router-link>
                            </div>
                        </div>

                        <div class="divider">
                            <div class="brn-ser">
                                <img :src="banner_sec.banner_section_right_image" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="partner-sec-logo">
                <div class="container">
                    <h4>Institutional Borrowing Partners</h4>
                    <div class="prtnr-logo-part">
                        <div><img src="../../assets/images/partner-logo7.png" alt="Bluex"/></div>
                        <div><img src="../../assets/images/partner-logo-8.png" alt="Msl"/></div>
                    </div>
                </div>
            </div> -->

            <div class="hw-wrk">
                <div class="container">
                    <div class="title">
                        <h2>{{sec2_how_it_works.heading_how_it_works_section}}</h2>
                        <p>{{sec2_how_it_works.how_it_works_section_sub_heading}}</p>
                    </div>

                    <div class="gkp-wap gkp-wap-1">

                        <div class="item-px" v-for="(row) in sec2_how_it_works.how_it_works_card_section_one" :key="row">
                            <span class="bred-tlx" style="background: #239CFF;">{{row.card_tag}}</span>
                            <div class="itmpf">
                                <img :src="row.card_image" alt="" />
                            </div>
                            <div class="ttx">
                                <h4>{{row.card_title}}</h4>
                                <p v-html="row.card_description"></p>
                            </div>
                        </div>                       
                    </div>
                    <div class="spt-title">
                        <h5>{{sec2_how_it_works.how_it_works_card_section_two_heading}}</h5>
                    </div>
                    <div class="gkp-wap gkp-wap-2">
                        <div class="item-px" v-for="(row) in sec2_how_it_works.how_it_works_card_section_two" :key="row">
                            <span class="bred-tlx" style="background: #35B58E;">{{row.section_two_card_tag}}</span>
                            <div class="itmpf">
                                <img :src="row.section_two_card_image" alt="" />
                            </div>
                            <div class="ttx">
                                <h4>{{row.section_two_card_title}}</h4>
                                  <p v-html="row.card_description"></p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="capital-platform">
                <div class="container">   
                    <div class="wrapper gap-top gap-bottom">
                        <div class="divider">
                            <div class="item global-itm">
                                <div class="img-aside">
                                    <img :src="sec3_card.first_card_image" alt=""/>
                                </div>
                                <div class="ttx">
                                    <h3>{{sec3_card.first_card_title}}</h3>                                   
                                    <p v-html="sec3_card.first_card_description"> </p>
                                </div>
                            </div>
                        </div>
                        <div class="divider">
                            <div class="item global-itm">
                                <div class="img-aside">
                                    <img :src="sec3_card.second_card_image" alt="access"/>
                                </div>
                                <div class="ttx">
                                     <h3>{{sec3_card.second_card_title}}</h3>                                   
                                    <p v-html="sec3_card.second_card_description"> </p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

            </div>

        



        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from "vuex";
import { useMeta } from 'vue-meta'
import { onMounted } from 'vue';

export default {
    name: 'App',
    data() {
        return {
            isload: false,
            // isloading:false,
            banner_sec: '',
            sec2_how_it_works: '',
            sec3_card: '',

        }
    },
    mounted() {
        // this.isloading=true;
        // this.GetBorrow();
    },
    computed: {
        ...mapState({
            borrow: state => state.webapiMod.borrow
        }),
    },
    watch: {
        borrow() {
            this.banner_sec = this.borrow.section1_banner_sec;
            this.sec2_how_it_works = this.borrow.sec2_how_it_works;
            this.sec3_card = this.borrow.sec3_card;
            this.isload = true;
            // this.isloading=false;
        }
    },
    methods: {
        ...mapActions({
            GetBorrow: "webapiMod/GetBorrow",
        }),
    },
    created() {
        if (this.borrow.length == 0) {
            this.GetBorrow();
        } else {
            this.banner_sec = this.borrow.section1_banner_sec;
            this.sec2_how_it_works = this.borrow.sec2_how_it_works;
            this.sec3_card = this.borrow.sec3_card;
            this.isload = true;
        }
    },
    setup() {
        const { meta } = useMeta({});
        const wpApiUrl = process.env.VUE_APP_API_BASE_URL;
        onMounted(async () => {
            let response = await fetch(wpApiUrl + 'wp/v2/pages?slug=borrow');
            let data = await response.json();
            data[0].yoast_head_json.schema = {};
            let dataset = data[0].yoast_head_json;
            dataset.canonical = window.location.href;
            dataset.og_url = window.location.href;
            Object.entries(dataset).forEach(([key, value]) => {
                if (value != '')
                    meta[key] = value;
            });
            // console.log(dataset)
            // console.log(meta)
        });
        return { meta };
    },
};
</script>
