<template>
  <div class="mnht off-white">
        <div>
          <div class="banner banner_borrow fix-top banner-white">
             <div class="container">
                <div class="wrapper">
                    <div class="divider">
                        <div class="text-area">
                            <h2>Lend Stablecoins to Growing Businesses Directly <span>On-Chain</span> </h2>
                            <p>Edge is built on our proven cSigma Institutional platform that brings AI-driven integrated credit analysis and risk management to on-chain lending. Through Community Loan Pools, you can lend to companies in real-world industries in a transparent way without any hidden fees.</p>
                            <a href="https://app.csigma.finance/" class="btn-ik yelw-cta">Start lending <img src="/img/arrow_right_blck.3f8cb5de.svg" alt="Right-arrow"></a>
                            <a href="https://csigma-edge.gitbook.io/csigma-edge " class="btn-ik doc-cta">Docs <img src="@/assets/images/icon/arrow_right_blck.svg" class="ml" alt="Right-arrow"></a>
                        </div>
                    </div>
                    <div class="divider">
                        <div class="brn-ser">
                            <img src="@/assets/images/Frame2.svg" alt="Edge Hero Image">
                        </div>
                    </div>
                </div>
             </div>
          </div>
          <div class="partner-logo">
              <div class="container"><div class="partner-logo-sec"><div class="flex logo-brdr"><h3>13K</h3><p>Loans</p></div><div class="flex logo-brdr"><h3>34K</h3><p>SMB borrowers</p></div><div class="flex logo-brdr"><h3>$90M</h3><p>Debt Capacity</p></div><div class="flex logo-brdr"><h3>$42M</h3><p>Lender AUM</p></div></div></div>
          </div>
           <div class="container">
            <div class="video-sec edge-video" v-if="works_video_section">
                     <h2>How to Start Lending</h2>
                     <div class="video-size">

                            <div class="video-container">
                                <!-- <img :src="works_video_section.works_video_section_video_image" width="1023" height="589"  alt="Poster" class="responsive-img" id="video-poster"> -->
                                <!-- <iframe :src="works_video_section.works_video_section_video_url" id="video-iframe" width="1023" height="350" style="display:none"></iframe> -->
                                <iframe src="https://www.youtube.com/embed/_ohbipqbpdA?si=YIIH1q1Bku7Et994" id="video-iframe" width="1023" height="350" ></iframe>
                            </div>

                      </div>
                    
                  </div>
           </div>
          <div class="container">
             <div class="lead-instantly">
                <h2>
                    Lend Instantaneously
                </h2>
                <h5>
                    Get started in minutes. Supply stablecoins and join a network of community lenders<br> making an impact on SMBs and growing businesses around the world.
                </h5>
                 <div class="features">
                    <div class="feature-boxled feature-1">
                        <div>
                    <div class="feature-icon">
                        <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.30475 9.24463H32.1015C35.0756 9.24463 37.4765 11.6455 37.4765 14.6196V20.568" stroke="#0E406D" stroke-width="2.95098" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M12.9664 3.5835L7.30475 9.24511L12.9664 14.9068" stroke="#0E406D" stroke-width="2.95098" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M37.4765 33.755H12.6797C9.70558 33.755 7.30475 31.3542 7.30475 28.38V22.4316" stroke="#0E406D" stroke-width="2.95098" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path d="M31.8148 39.4166L37.4765 33.755L31.8148 28.0933" stroke="#0E406D" stroke-width="2.95098" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </div>
                    <h3>Diversify your crypto portfolio</h3>
                    <p>
                        Diversify your Crypto portfolio by lending to Real World Credit
                        and increase consistency of your yields.
                    </p>
                        </div>
                        <img  src="@/assets/images/crypto.svg" alt="Diversify your crypto portfolio" class="img">
                    </div>
                    <div class="feature-boxled">
                        <div class="feature-icon">
                        <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.5156 23.6934H15.7656" stroke="#0E406D" stroke-width="3.32" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M5.14062 23.6944V13.8769C5.14062 9.5419 8.64688 6.03564 12.9819 6.03564H24.9244C29.2594 6.03564 32.7656 8.73439 32.7656 13.0694" stroke="#0E406D" stroke-width="3.32" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M38.0356 25.9246C36.9731 26.9446 36.4631 28.517 36.8881 30.132C37.4194 32.1083 39.3744 33.3621 41.4144 33.3621H43.3906V36.4434C43.3906 41.1396 39.5869 44.9434 34.8906 44.9434H13.6406C8.94437 44.9434 5.14062 41.1396 5.14062 36.4434V21.5684C5.14062 16.8721 8.94437 13.0684 13.6406 13.0684H34.8906C39.5656 13.0684 43.3906 16.8934 43.3906 21.5684V24.6495H41.0956C39.9056 24.6495 38.8218 25.1171 38.0356 25.9246Z" stroke="#0E406D" stroke-width="3.32" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M47.6407 26.8179V31.1954C47.6407 32.3854 46.6632 33.363 45.4519 33.363H41.3507C39.0557 33.363 36.952 31.6842 36.7607 29.3892C36.6332 28.0505 37.1432 26.7967 38.0357 25.9255C38.8219 25.118 39.9057 24.6504 41.0957 24.6504H45.4519C46.6632 24.6504 47.6407 25.6279 47.6407 26.8179Z" stroke="#0E406D" stroke-width="3.32" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        </div>
                        <h3>Deposit in Minutes</h3>
                        <p>
                        Lend to real world credit borrowers without any traditional
                        finance (TradFi) experience.
                        </p>
                    </div>
                    <div class="feature-boxled">
                        <div class="feature-icon">
                        <img src="@/assets/images/icon/using-ai.svg">
                        </div>
                        <h3>On-Chain Credit Management Using AI</h3>
                        <p>
                        cSigma Institutional on-boards high-quality borrowers and creates
                        pre-built credit portfolios using our AI credit engine.
                        </p>
                    </div>
                    <div class="feature-boxled">
                        <div class="feature-icon">
                        <svg width="46" height="51" viewBox="0 0 46 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_107_666)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M45 12.8597L22.4658 0L1 12.25V36.75L22.4658 49L45 36.1403L24.6027 24.5L45 12.8597ZM4.20541 12.8597L21.4481 3.01971V22.6998L4.20541 12.8597ZM23.5852 3.07779L40.7262 12.8597L23.5852 22.6417V3.07779ZM21.4481 45.9803L4.20542 36.1403L21.4481 26.3002V45.9803ZM40.7262 36.1403L23.5852 45.9222V26.3583L40.7262 36.1403ZM20.3288 24.5L3.13695 14.689V34.311L20.3288 24.5Z" fill="#0E406D"></path>
                            </g>
                            <defs>
                            <filter id="filter0_d_107_666" x="0" y="0" width="46" height="51" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                                <feOffset dy="1"></feOffset>
                                <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
                                <feComposite in2="hardAlpha" operator="out"></feComposite>
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"></feColorMatrix>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_107_666"></feBlend>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_107_666" result="shape"></feBlend>
                            </filter>
                            </defs>
                        </svg>
                        </div>
                        <h3>Earn SIGMA Token Rewards</h3>
                        <p>
                        Earn SIGMA tokens and join the growing cSigma community built
                        around Real World Credit.
                        </p>
                    </div>
                    <div class="feature-boxled">
                        <div class="feature-icon">
                        <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.26562 46.75H45.5156" stroke="#0E406D" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12.7906 17.8076H9.39062C8.22187 17.8076 7.26562 18.7639 7.26562 19.9326V38.2501C7.26562 39.4189 8.22187 40.3751 9.39062 40.3751H12.7906C13.9593 40.3751 14.9156 39.4189 14.9156 38.2501V19.9326C14.9156 18.7639 13.9593 17.8076 12.7906 17.8076Z" stroke="#0E406D" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M28.091 11.0288H24.691C23.5223 11.0288 22.566 11.9851 22.566 13.1538V38.2501C22.566 39.4188 23.5223 40.3751 24.691 40.3751H28.091C29.2597 40.3751 30.216 39.4188 30.216 38.2501V13.1538C30.216 11.9851 29.2597 11.0288 28.091 11.0288Z" stroke="#0E406D" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M43.3904 4.25H39.9904C38.8217 4.25 37.8654 5.20625 37.8654 6.375V38.25C37.8654 39.4188 38.8217 40.375 39.9904 40.375H43.3904C44.5591 40.375 45.5154 39.4188 45.5154 38.25V6.375C45.5154 5.20625 44.5591 4.25 43.3904 4.25Z" stroke="#0E406D" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        </div>
                        <h3>Track Pool Utilization</h3>
                        <p>
                        See which institutional pools your funds are being supplied to.
                        </p>
                    </div>
                 </div>
                 <div class="lunch-app-sec gap-top text-center">
                    
                    <a href="https://app.csigma.finance/" class="explore-btn btn-ik yelw-cta">Launch App </a>
                 </div>
             </div>
          </div>
          <div class="streamlined-process hw-edge-wrk">
            <div class="container">
                <div class="section-head section-head_tow">
                    <h2>How it Works</h2>
                </div>
                <div class="lending-sec">
                    <div class="image-sec-lending text-center">
                        <img src="@/assets/images/work-flow.svg" alt="account">
                    </div>
                    <div class="text-lending-sec">
                        <div class="lending-sec-item">
                            <div class="text-number">1</div>
                            <div class="lending-sec-item-text">
                                <h3>You</h3>
                                <div>
                                    <p>Lend and contribute stablecoins to the cSigma Edge vault.</p>
                                </div>
                            </div>
                        </div>
                        <div class="lending-sec-item">
                            <div class="text-number">2</div>
                                <div class="lending-sec-item-text">
                                    <h3>Capital Deployed in Borrower Portfolio</h3>
                                    <div>
                                        <p>Edge Automated Process lends aggregated capital to borrowers and monitors risk.</p>
                                   </div>
                                </div>
                             </div>
                        <div class="lending-sec-item">
                            <div class="text-number">3</div>
                            <div class="lending-sec-item-text">
                                <h3>Receive Principal and Interest</h3>
                                <div>
                                    <p>Borrowers pay back at maturity and Edge Vaults distribute the principal and interest proportionally to all lenders.</p>
                                </div>
                            </div>
                        </div>
                        <div class="btn-ylw-left">
                            <a href="https://app.csigma.finance/" class="explore-btn btn-ik blue-cta">Start Lending 
                            </a>
                        </div>
                    </div>
                  
                </div>
            </div>
          </div>
            <div class="blog-sec">
                <div class="container">
                    <div class="heading-flx">
                        <div> <h2>{{ blog_section.blog_section_title }}</h2></div>
                        <div><a :href=" blog_section.blog_section_button_url"  class="more-btn blog-cta" target="_blank">{{ blog_section.blog_section_button_name }}</a></div>
                   </div>
                <BlogSlider :blogs="blog_data" />
                </div>
            </div>
            <div class="text-center browse-top">
                <a href="https://csigma.medium.com/">Browse more posts</a>
            </div>
        </div>
 </div>
</template>

<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapState, mapActions } from 'vuex';
import { useMeta } from 'vue-meta'
import { onMounted } from 'vue';

import BlogSlider from '../includes/BlogSlider.vue';

// import axios from 'axios';
// const url = process.env.VUE_APP_API_BASE_URL;
export default {
    name: 'App',
    components: {
   
    BlogSlider,
   
  },
    data() {
        return {
            env_data: this.$apiUrl,
            isload: false,
            banner_sec: '',
            works_video_section: '',
            permissioned_pools: '',
            institutional_pools: '',
            lend_usdc_and_usdt: '',
            our_backers: '',
            testimonials: '',
            audited_by: '',
            earn_up: '',
            blog_data: '',
            blog_section: '',
            // banner_sec_img: require('@/assets/images/banner-img.png')
        }
    },
    computed: {
        // ...mapGetters('counterMod', ['personName']),
        ...mapState({home: state => state.webapiMod.home}),
    },
     watch: {
        home() {
            this.banner_sec = this.home.section1_banner_sec;
            this.works_video_section = this.home.works_video_section;
            this.permissioned_pools = this.home.section2_permissioned_pools;
            this.institutional_pools = this.home.section_institutional_pools;
            this.lend_usdc_and_usdt = this.home.section_lend_usdc_and_usdt;
            this.our_backers = this.home.section_our_backers;
            this.testimonials= this.home.testimonials;
            this.blog_data = this.home.blog_data;
            this.blog_section = this.home.blog_section;
            
            this.audited_by = this.home.section_audited_by;
            
            this.earn_up = this.home.section_earn_up;
            setTimeout(function() {              
                document.getElementById('video-iframe').style.display = 'block';
            }, 1000); 
            this.isload = true;
            // this.isloading=false;
        }
    },
    methods: {
        ...mapActions({ GetHome: "webapiMod/GetHome", }),
        getHeight(val) {
            return val + 'px !important';
        }
    },
    mounted() {
        // console.log(this.env_data);
    },
    created() {
        // console.log('testing 2nd render');
        // console.log(this.home);
        this.GetHome();
        // if (this.home.length == 0) {
        //     this.GetHome();
        // } else {
        //     this.banner_sec = this.home.section1_banner_sec;
        //     this.works_video_section = this.home.works_video_section;
        //     this.permissioned_pools = this.home.section2_permissioned_pools;
        //     this.institutional_pools = this.home.section_institutional_pools;
        //     this.lend_usdc_and_usdt = this.home.section_lend_usdc_and_usdt;
        //     this.our_backers = this.home.section_our_backers;
        //     this.testimonials= this.home.testimonials;
        //     this.audited_by = this.home.section_audited_by;
        //     this.blog_data = this.home.blog_data;
        //     this.blog_section = this.home.blog_section;
        //     this.earn_up = this.home.section_earn_up;
        //     this.isload = true;
        //     this.GetHome();
        // }
    },
    setup() {
        const { meta } = useMeta({});
        console.log(meta);
        console.log(window.location.href);
        const wpApiUrl = process.env.VUE_APP_API_BASE_URL;
        onMounted(async () => {
            // let response = await fetch('https://ricoman.bigwavedevelopment.com/back-end/wp-json/wp/v2/pages?slug=home-page');
            let response = await fetch(wpApiUrl + 'wp/v2/pages?slug=home');
            let data = await response.json();
            data[0].yoast_head_json.schema = {};
            let dataset = data[0].yoast_head_json;
            dataset.canonical = window.location.href;
            dataset.og_url = window.location.href;
            Object.entries(dataset).forEach(([key, value]) => {
                if (value != '')
                    meta[key] = value;
            });
            // console.log(dataset)
            // console.log(meta)
        });
        return { meta };
    },
}
</script>