<template>
    <div class="modal-mask">
        

        <div class="modal-wrapper">
            <div class="modal-mask-bg " @click="$emit('close')"></div>
            <div class="modal-container">
                
                <button class="modal-close-button" @click="$emit('close')">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="22" height="22">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                </button>

            <div class="modal-header">
                
                <slot name="header">
                default header
                </slot>
            </div>  

            <div class="modal-body">
                <slot name="body">
                default body
                </slot>
            </div>

            <!-- <div class="modal-footer">
                <slot name="footer">
                default footer
                <button class="modal-default-button" @click="$emit('close')">
                    OK
                </button>
                </slot>
            </div> -->
            </div>
        </div>
      
    </div>
</template>

<script>
    // import Modal from '../includes/Modal.vue'

    export default {
        name: 'App',
    
    }

</script>