<template>
    <div class="banner about__banner fix-top">
        <div class="container">
            <div style="text-align: center;">
                <h2>Page Not Found</h2>
                <router-link to="/" class="btn-ik yelw-cta" style="margin-top: 20px;">Go to Home</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { useMeta } from 'vue-meta'

export default ({
    name: 'PageNotFound',
    setup() {
        const { meta } = useMeta({});
        meta.title = 'Page not Found'
        meta.description = 'Opps, are you lost? Visit Home page to start again.'
        return { meta };
    },
})
</script>